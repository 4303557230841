export default async function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  // eslint-disable-next-line require-await
  const findEl = async (hash, x = 0) => {
    return (
      document.querySelector(hash) ||
      new Promise((resolve) => {
        if (x > 50) {
          return resolve(document.querySelector('#app'))
        }
        setTimeout(() => {
          resolve(findEl(hash, ++x || 1))
        }, 100)
      })
    )
  }

  if (to.hash) {
    const el = await findEl(to.hash)
    if ('scrollBehavior' in document.documentElement.style) {
      // return window.scrollTo({
      //   top: el.getBoundingClientRect().top + window.pageYOffset,
      //   behavior: 'smooth'
      // })
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
      return window.scrollTo(0, el.getBoundingClientRect().top + window.pageYOffset)
    }
  }

  return { x: 0, y: 0 }
}
